.jodit-toolbar__box:not(:empty) {
  background-color: rgb(240, 240, 240);
}

.jodit-container {
  border-radius: 5px !important;
}
.jodit-react-container:hover {
  border: 1px solid black;
  border-radius: 5px !important;
}

.jodit-add-new-line {
  display: none !important;
}

.jodit-placeholder {
    color: rgba(0, 0, 0, 0.6) !important;
}
