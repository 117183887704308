.react-tag-input {
  font-size: 14px; /* specific font size */
  font-size: 1.5rem; /* responsive font size */
  background: rgb(240, 240, 240);
  /* color: #333;  */
  /* border: 1px solid #a8a8a8; */
}
.react-tag-input__input {
  font-size: 16px;
  background: transparent;
  /* color: #333; */
}
/* .react-tag-input__input::placeholder,
.react-tag-input__input:-moz-placeholder,
.react-tag-input__input:-ms-input-placeholder,
.react-tag-input__input::-moz-placeholder,
.react-tag-input__input::-webkit-input-placeholder {
  color: #333; 
} */
.react-tag-input__tag {
  /* background: #1cbb79; */
}
.react-tag-input__tag__remove {
  /* background: #c7175b; */
}
/* .react-tag-input__tag__remove:before,
.react-tag-input__tag__remove:after {
  background: rgb(17, 46, 126); 
} */
