@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  display: flex;
  /* flex-direction: row; */
  height: 100vh;
  width: 100vw;
  /* background-color: blue; */
  margin: 0;
}

@font-face {
  font-family: "Graphik";

  src: local("Graphik"), url(/static/media/Graphik-Light.1bff1621.otf) format("opentype");
}
@font-face {
  font-family: "Graphik";
  font-weight: lighter;
  font-style: italic;
  src: local("Graphik"), url(/static/media/Graphik-LightItalic.2a0f75a6.otf) format("opentype");
}
@font-face {
  font-family: "Graphik";
  font-weight: 400;
  src: local("Graphik"), url(/static/media/Graphik-Medium.870f914f.otf) format("opentype");
}
@font-face {
  font-family: "Graphik";
  font-style: italic;
  font-weight: 400;
  src: local("Graphik"),
    url(/static/media/Graphik-MediumItalic.55f9a34d.otf) format("opentype");
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(/static/media/Graphik-Regular.2552527e.otf) format("opentype");
}
@font-face {
  font-family: "Graphik";
  font-style: italic;
  src: local("Graphik"),
    url(/static/media/Graphik-RegularItalic.1bb44f45.otf) format("opentype");
}
@font-face {
  font-family: "Graphik";
  font-weight: 400;
  font-style: italic;
  src: local("Graphik"),
    url(/static/media/Graphik-SemiboldItalic.606f93e5.otf) format("opentype");
}

@font-face {
  font-family: "InvescoEditor";
  font-weight: 900;
  src: local("InvescoEditor"),
    url(/static/media/InvescoEditor-Regular.a2fe347e.otf) format("opentype");
}

.jodit-toolbar__box:not(:empty) {
  background-color: rgb(240, 240, 240);
}

.jodit-container {
  border-radius: 5px !important;
}
.jodit-react-container:hover {
  border: 1px solid black;
  border-radius: 5px !important;
}

.jodit-add-new-line {
  display: none !important;
}

.jodit-placeholder {
    color: rgba(0, 0, 0, 0.6) !important;
}

.react-tag-input {
  font-size: 14px; /* specific font size */
  font-size: 1.5rem; /* responsive font size */
  background: rgb(240, 240, 240);
  /* color: #333;  */
  /* border: 1px solid #a8a8a8; */
}
.react-tag-input__input {
  font-size: 16px;
  background: transparent;
  /* color: #333; */
}
/* .react-tag-input__input::placeholder,
.react-tag-input__input:-moz-placeholder,
.react-tag-input__input:-ms-input-placeholder,
.react-tag-input__input::-moz-placeholder,
.react-tag-input__input::-webkit-input-placeholder {
  color: #333; 
} */
.react-tag-input__tag {
  /* background: #1cbb79; */
}
.react-tag-input__tag__remove {
  /* background: #c7175b; */
}
/* .react-tag-input__tag__remove:before,
.react-tag-input__tag__remove:after {
  background: rgb(17, 46, 126); 
} */

